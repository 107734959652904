"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.News = function News() {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var allNews = document.querySelectorAll('.news');
    var buttonAddNews = document.querySelector('.show-more-news');
    var buttonRemoveNews = document.querySelector('.show-less-news');
    var buttonAddNewsMobile = document.querySelector('.show-more-news-mobile');
    var numberNews = 6;

    if (buttonRemoveNews) {
      buttonRemoveNews.style.display = "none";
    }

    if (allNews.length && allNews.length > numberNews && buttonAddNews) {
      buttonAddNews.addEventListener('click', function (e) {
        e.preventDefault();
        var newNumberNews = numberNews + 6;

        if (allNews.length < newNumberNews) {
          numberNews = allNews.length;
          buttonAddNews.style.display = "none";
          buttonRemoveNews.style.display = "block";
        } else {
          buttonRemoveNews.style.display = "block";
          numberNews += 6;
        }

        ;
        addNews();
      });
    }

    if (buttonRemoveNews) {
      buttonRemoveNews.addEventListener('click', function (e) {
        e.preventDefault();
        var newNumberNews = numberNews - 6;

        if (newNumberNews < 6) {
          numberNews = 6;
          buttonRemoveNews.style.display = "none";
          buttonAddNews.style.display = "block";
        } else {
          numberNews = newNumberNews;
          buttonAddNews.style.display = "block";
        }

        showNews();
        addNews();
      });
    }

    if (buttonAddNewsMobile && allNews.length) {
      buttonAddNewsMobile.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector('.all-news').classList.add("is-active-mobile");
        buttonAddNewsMobile.style.display = "none";
        allNews.forEach(function (news) {
          news.style.display = "block";
        });
      });
    }

    var showNews = function showNews() {
      if (allNews.length) {
        allNews.forEach(function (news) {
          news.style.display = "none";
        });
      }
    };

    var addNews = function addNews() {
      if (allNews.length) {
        for (var index = 0; index < numberNews; index++) {
          allNews[index].style.display = "block";
        }
      }
    };

    showNews();
    addNews();
    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();