"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.Share = function Share() {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var $button = document.querySelector('[data-share]');

    if ($button) {
      $button.addEventListener('click', function (e) {
        e.preventDefault();
        var shareObject = {
          title: document.title,
          text: document.querySelector('meta[name="description"]').content,
          url: window.location.href
        };
        console.log(shareObject);
        navigator.share(shareObject);
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();