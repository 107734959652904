"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */

site.ScrollUp = function ScrollUp() {
  'use strict';
  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  /**
   * Initializes the class.
   * @public
   */

  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;
    var arrowUp = document.querySelectorAll('.arrow-scroll-up');

    if (arrowUp) {
      arrowUp.addEventListener('click', function (e) {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    }

    return true;
  }; // Expose public methods & properties


  return {
    init: init
  };
}();